//fichero que se usa para crear hotspots en cms (fama, famaliving)

$(function() {
    let $adaptableSetTypeSelectorField = $('.js-AdaptableSetTypeSelector')
    let $imageHotspots = $('.js-hotspot-image');

    setFamalivingColor($('button.current-site').text() || "");

    if($imageHotspots.length > 0) {
        //console.log('$imageHotspots.length', $imageHotspots.length );
        $imageHotspots.click(clicksOnHotspotImage);
        $('.hotspot-marker').click(hotspotClicked.bind(this));
        $('.js-confirm-hotspot').click(confirmHotspot.bind(this));
        $('.hotspot-return').click(returnButton.bind(this));
        $('.js-save-hotspots').click(saveAll.bind(this));
        $('.js-delete-hotspot').click(deleteHotspot.bind(this));
        $('.js-delete-hotspots').click(deleteHotspots.bind(this));
        $('.close').click(closeModal.bind(this));
        $('.js-cancel').click(closeModal.bind(this));
        var hotspotModal = $('.hotspot-modal');
    }

    if($adaptableSetTypeSelectorField.length > 0) {
        //console.log('Hotspots.js2');
        showCurrentAdaptableSetType1N();
        $adaptableSetTypeSelectorField.find('select[name=model]').on('change', function() {
            showCurrentAdaptableSetType1N();
        });
    }

    function returnButton(event){
        //console.log('Hotspots.js3 returnButton');
        window.location.replace(event.target.dataset.href);
    }

    function clicksOnHotspotImage(event){
        //console.log('Hotspots.js4 clicksOnHotspotImage');
        event.preventDefault();
        let container = $('.js-hotspot-image');
        let offset = container.offset();
        let positionX = event.pageX - offset.left;
        let positionY = event.pageY - offset.top;
        let hostpots = $('#hotspots');
        let icon = hotspots.dataset.icon;
        let imagemap = $('#imageMap')[0];

//array of langs, create attr dinamicly
        let stringLangs=$('.js-modal-body-langs').data('langs');
        let arrLans=stringLangs.split(',');
        arrLans=arrLans.splice(0,arrLans.length-1);
        let stringData="";
        arrLans.forEach((value)=> {
            //console.log(value);
            stringData=stringData+"data-" + value + "-title='' data-" + value + "-text='' data-" + value + "-url='' ";
        });

        let percentajePositionX = ((positionX-15) / container.width() * 100);
        let percentajePositionY = ((positionY-15) / container.height() * 100);
        let hotspotId = getHotspotNextId();

        let insertingelement = `<img
            class="hotspot-marker"
            src="${icon}"
            style="position: absolute; top:${percentajePositionY}%; left: ${percentajePositionX}%; max-height: 30px"
            data-hotspot-id="${hotspotId}"
            `+
            stringData
            +
               /*
            data-en-title=""
            data-en-text=""
            data-en-url=""
            data-fr-title=""
            data-fr-text=""
            data-fr-url=""
            data-de-title=""
            data-de-text=""
            data-de-url=""
            data-es-title=""
            data-es-text=""
            data-es-url=""
            */

            `
            data-height="${percentajePositionY}"
            data-width="${percentajePositionX}"></img>`;

            //console.log('insertingelement', insertingelement);

        imagemap.innerHTML += insertingelement;
        hotspotModal.attr('style', 'display:block;');
        hotspotModal.attr('data-width', percentajePositionX);
        hotspotModal.attr('data-height', percentajePositionY);
        hotspotModal.attr('data-id', hotspotId);
        $('.hotspot-marker').click(hotspotClicked.bind(this));
        $('.js-hotspot-image').unbind();
        $('.js-hotspot-image').click(clicksOnHotspotImage.bind(this));
    }

    function saveAll(event) {
        //console.log('Hotspots.js5 saveAll');

        let stringLangs=$('.js-modal-body-langs').data('langs');
        let arrLans=stringLangs.split(',');
        arrLans=arrLans.splice(0,arrLans.length-1);
        let langText, langTitle, langUrl;
        let arrHotspots=[];

        /*let hotspots = $('.hotspot-marker').map(function () {
            return {
                hotspotId: $(this).data('hotspot-id'),


                esTitle: $(this).data('es-title'),
                esText: $(this).data('es-text'),
                esUrl: $(this).data('es-url'),

                enTitle: $(this).data('en-title'),
                enText: $(this).data('en-text'),
                enUrl: $(this).data('en-url'),

                frTitle: $(this).data('fr-title'),
                frText: $(this).data('fr-text'),
                frUrl: $(this).data('fr-url'),

                deTitle: $(this).data('de-title'),
                deText: $(this).data('de-text'),
                deUrl: $(this).data('de-url'),

                topPercent: $(this).data('height'),
                leftPercent: $(this).data('width'),
                featuredImage: hotspotModal[0].dataset.modelId,
            };
        }).get();*/

        $('.hotspot-marker').each(function(){
            let hotspot={};
            arrLans.forEach((value)=> {
                langTitle=value+'Title';
                langText=value+'Text';
                langUrl=value+'Url';
                hotspot[langTitle]= $(this).data(''+value+'-title'),//???
                hotspot[langText]= $(this).data(''+value+'-text'),
                hotspot[langUrl]= $(this).data(''+value+'-url')
            });
            hotspot.hotspotId= $(this).data('hotspot-id');
            hotspot.topPercent= $(this).data('height');
            hotspot.leftPercent= $(this).data('width');
            hotspot.featuredImage= hotspotModal[0].dataset.modelId;

            arrHotspots.push(hotspot);
        })


//console.log('arrHotspots', arrHotspots);
        let data = {
            hotspots: arrHotspots,
            site: $('.body-hotspots').attr('data-site-id')
        }

        axios
            .post('/api/save-hotspots', data)
            .then(function (response) {
                //console.log('response',response);
                document.location.replace(event.target.dataset.href);
            })
            .catch(function (error) {
                alert(error)
            })
    }

    function deleteHotspots(){
        //console.log('Hotspots.js6 deleteHotspots');
        let url = event.target.dataset.href;
        let data = {
            featuredImage: hotspotModal[0].dataset.modelId,
        };
        axios
            .post('/api/delete-hotspots', data)
            .then(function (response) {
                document.location.replace(url);
            })
            .catch(function (error) {
                alert(error)
            })
    }

    function deleteHotspot()
    {
        //console.log('Hotspots.js7 deleteHotspot');
        let hotspotId = hotspotModal.attr('data-id');
        $('.hotspot-marker[data-hotspot-id="'+hotspotId+'"]').remove();
        closeModal();
    }

    function confirmHotspot()   //  todo cambiar aqui por foreach
    {
        //console.log('Hotspots.js10 confirmHotspot');

        //array of langs, create attr dinamicly
        let stringLangs=$('.js-modal-body-langs').data('langs');
        let arrLans=stringLangs.split(',');
        arrLans=arrLans.splice(0,arrLans.length-1);
        let langTitle, langText, langUrl;

        let hotspotId = hotspotModal.attr('data-id');
        let marker = $('.hotspot-marker[data-hotspot-id="'+hotspotId+'"]');
        marker[0].dataset.hotspotId = hotspotId;


        arrLans.forEach((value)=> {
            //console.log(value);
            langTitle=value+'Title';
            langText=value+'Text';
            langUrl=value+'Url';
            //console.log(langTitle, langText, langUrl);
            marker[0].dataset[langTitle]=$('#hotspot-'+value+'-titulo').val();
            marker[0].dataset[langText]=$('#hotspot-'+value+'-texto').val();
            marker[0].dataset[langUrl]=$('#hotspot-'+value+'-url').val();
        })
/*
        marker[0].dataset.esTitle = $('#hotspot-es-titulo').val();
        marker[0].dataset.esText = $('#hotspot-es-texto').val();
        marker[0].dataset.esUrl = $('#hotspot-es-url').val();
        marker[0].dataset.enTitle = $('#hotspot-en-titulo').val();
        marker[0].dataset.enText = $('#hotspot-en-texto').val();
        marker[0].dataset.enUrl = $('#hotspot-en-url').val();
        marker[0].dataset.frTitle = $('#hotspot-fr-titulo').val();
        marker[0].dataset.frText = $('#hotspot-fr-texto').val();
        marker[0].dataset.frUrl = $('#hotspot-fr-url').val();
        marker[0].dataset.deTitle = $('#hotspot-de-titulo').val();
        marker[0].dataset.deText = $('#hotspot-de-texto').val();
        marker[0].dataset.deUrl = $('#hotspot-de-url').val();
        */
        marker[0].dataset.height = hotspotModal.attr('data-height');
        marker[0].dataset.width = hotspotModal.attr('data-width');
        closeModal();
    }

    function hotspotClicked(event)
    {
        //console.log('Hotspots.js8 hotspotClicked', event.target.getAttribute("data-hotspot-id"));

        let elemId=event.target.getAttribute("data-hotspot-id");
        //array of langs, create attr dinamicly
        let stringLangs=$('.js-modal-body-langs').data('langs');
        let hotspotModal=$('.modal.hotspot-modal');
        let arrLans=stringLangs.split(',');
        arrLans=arrLans.splice(0,arrLans.length-1);

        //let hotspotId = hotspotModal.attr('data-id');
        let hotspotId = elemId;
        //console.log('hotspotId', hotspotId);
        let marker = $('.hotspot-marker[data-hotspot-id="'+hotspotId+'"]');
        marker[0].dataset.hotspotId = hotspotId;

        $('.js-hotspot-image').click(clicksOnHotspotImage);
        hotspotModal.attr('style', 'display:block;');
        hotspotModal.attr('data-width', event.target.dataset.width);
        hotspotModal.attr('data-height', event.target.dataset.height);
        hotspotModal.attr('data-id', event.target.dataset.hotspotId);

        arrLans.forEach((value)=> {
            let langTitle=value + 'Title';
            let langText=value + 'Text';
            let langUrl= value + 'Url';
            //console.log('value', value);
            $('#hotspot-'+value+'-titulo').val(event.target.dataset[langTitle]);
            $('#hotspot-'+value+'-texto').val(event.target.dataset[langText]);
            $('#hotspot-'+value+'-url').val(event.target.dataset[langUrl]);
        });
        /*
        $('#hotspot-es-titulo').val(event.target.dataset.esTitle);
        $('#hotspot-es-texto').val(event.target.dataset.esText);
        $('#hotspot-es-url').val(event.target.dataset.esUrl);
        $('#hotspot-en-titulo').val(event.target.dataset.enTitle);
        $('#hotspot-en-texto').val(event.target.dataset.enText);
        $('#hotspot-en-url').val(event.target.dataset.enUrl);
        $('#hotspot-fr-titulo').val(event.target.dataset.frTitle);
        $('#hotspot-fr-texto').val(event.target.dataset.frText);
        $('#hotspot-fr-url').val(event.target.dataset.frUrl);
        $('#hotspot-de-titulo').val(event.target.dataset.deTitle);
        $('#hotspot-de-texto').val(event.target.dataset.deText);
        $('#hotspot-de-url').val(event.target.dataset.deUrl);
        */
        $('.close').click(closeModal.bind(this));
        $('.js-cancel').click(closeModal.bind(this));
        $('.hotspot-marker').click(hotspotClicked.bind(this));
    }

    function getHotspotNextId()
    {
        //console.log('Hotspots.js9 getHotspotNextId');
        var num = $('.hotspot-marker').map(function() {
            return $(this).data('hotspot-id');
        }).get();
        if(num.length > 0) {
            var highest = Math.max.apply(Math, num);
            return (highest + 1);
        }else{
            return 1;
        }
    }

    function closeModal()
    {
        //console.log('Hotspots.js10 closeModal');
        hotspotModal.attr('style', 'display:none;');
        emptyModal();
    }

    function emptyModal()
    {
        //console.log('Hotspots.js11 emptyModal');
        $('.hotspot-modal input').val('');
        hotspotModal.attr('data-width', '');
        hotspotModal.attr('data-height', '');
        hotspotModal.attr('data-id', '');

        $('.js-hotspot-image').unbind();
        $('.js-hotspot-image').click(clicksOnHotspotImage.bind(this));
    }

    function showCurrentAdaptableSetType1N()
    {
        //console.log('Hotspots.js12 showCurrentAdaptableSetType1N');
        let $widgetField = $('.js-TypeWidget');
        let $corporateTextField = $('.js-TypeCorporateText');
        let $articleField = $('.js-TypeArticle');
        let $contestField = $('.js-TypeContest');
        let $sectionSliderField = $('.js-TypeSlider');
        let $blogSetField = $('.js-TypeBlogSet');

        let currentValue = $adaptableSetTypeSelectorField.find('select[name=model]').val();
        if(currentValue === 'widgets') {
            $corporateTextField.hide();
            $articleField.hide();
            $contestField.hide();
            $sectionSliderField.hide();
            $blogSetField.hide();
            $widgetField.show();
        }
        if(currentValue === 'corporateTexts') {
            $widgetField.hide();
            $articleField.hide();
            $contestField.hide();
            $sectionSliderField.hide();
            $blogSetField.hide();
            $corporateTextField.show();
        }
        if(currentValue === 'articles') {
            $widgetField.hide();
            $corporateTextField.hide();
            $contestField.hide();
            $sectionSliderField.hide();
            $blogSetField.hide();
            $articleField.show();
        }
        if(currentValue === 'sectionSlider') {
            $widgetField.hide();
            $corporateTextField.hide();
            $articleField.hide();
            $contestField.hide();
            $blogSetField.hide();
            $sectionSliderField.show();
        }
        if(currentValue === 'blogSets') {
            $widgetField.hide();
            $corporateTextField.hide();
            $articleField.hide();
            $contestField.hide();
            $sectionSliderField.hide();
            $blogSetField.show();
        }
        if(currentValue === 'contests') {
            $widgetField.hide();
            $corporateTextField.hide();
            $articleField.hide();
            $sectionSliderField.hide();
            $blogSetField.hide();
            $contestField.show();
        }
    }

    function setFamalivingColor(site){
        site = site.trim().toLowerCase();
        switch (site) {
            case 'famaliving':
                $('div.navbar-toggler-brand-wrapper').removeClass('bg-primary')
                $('div.navbar-toggler-brand-wrapper').css('background-color','#c70087')
        }
    }
});
